// extracted by mini-css-extract-plugin
export var base = "MarketingCaseStudyIndividualPage-module--base--20hsS";
export var rightAlignedContainer = "MarketingCaseStudyIndividualPage-module--rightAlignedContainer--LamiW";
export var rightAlignedContainerSmall = "MarketingCaseStudyIndividualPage-module--rightAlignedContainerSmall--1dMcK";
export var leftAlignedContainer = "MarketingCaseStudyIndividualPage-module--leftAlignedContainer--2-SRX";
export var blogContainer = "MarketingCaseStudyIndividualPage-module--blogContainer--2hx7n MarketingCaseStudyIndividualPage-module--base--20hsS";
export var container = "MarketingCaseStudyIndividualPage-module--container--1d_RP MarketingCaseStudyIndividualPage-module--base--20hsS";
export var largeContainer = "MarketingCaseStudyIndividualPage-module--largeContainer--3Q1YJ MarketingCaseStudyIndividualPage-module--base--20hsS";
export var mobileSmallPadding = "MarketingCaseStudyIndividualPage-module--mobileSmallPadding--3o906";
export var fullWidthMobile = "MarketingCaseStudyIndividualPage-module--fullWidthMobile--23kbm";
export var gridWithSidebar = "MarketingCaseStudyIndividualPage-module--gridWithSidebar--3oItQ";
export var noMaxWidth = "MarketingCaseStudyIndividualPage-module--noMaxWidth--3CWN8";
export var casestudyHero = "MarketingCaseStudyIndividualPage-module--casestudyHero--1xytF";
export var videoPlayer = "MarketingCaseStudyIndividualPage-module--videoPlayer--3LWFQ";
export var sectionHeader = "MarketingCaseStudyIndividualPage-module--sectionHeader--7rJTZ";
export var sectionHeader1 = "MarketingCaseStudyIndividualPage-module--sectionHeader1--3ez3i";
export var sectionHeader2 = "MarketingCaseStudyIndividualPage-module--sectionHeader2--1AqjM";
export var sectionHeader3 = "MarketingCaseStudyIndividualPage-module--sectionHeader3--10KCm";
export var pitch = "MarketingCaseStudyIndividualPage-module--pitch--3_Svj";
export var featuredContents = "MarketingCaseStudyIndividualPage-module--featuredContents--2XJA0";
export var testimonials = "MarketingCaseStudyIndividualPage-module--testimonials--Bqv4f";
export var copyLockupList = "MarketingCaseStudyIndividualPage-module--copyLockupList--9Ym6I";