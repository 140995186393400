import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CaseStudyHeroModule, { CaseStudyHeroDataPropsObject } from '../../components/CaseStudyHeroModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import FeaturedContentsModule, { FeaturedContentsDataPropsObject } from '../../components/FeaturedContentsModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import SectionHeaderModule, { SectionHeaderDataPropsObject } from '../../components/SectionHeaderModule';
import TestimonialsModule, { TestimonialsDataPropsObject } from '../../components/TestimonialsModule';
import VideoPlayerModule, { VideoPlayerDataPropsObject } from '../../components/VideoPlayerModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  casestudyHero,
  copyLockupList as copyLockupListClass,
  featuredContents as featuredContentsClass,
  pitch as pitchClass,
  sectionHeader as sectionHeaderClass,
  sectionHeader1,
  sectionHeader2,
  sectionHeader3,
  testimonials as testimonialsClass,
  videoPlayer as videoPlayerClass
} from './MarketingCaseStudyIndividualPage.module.css';

export default function MarketingCaseStudyIndividualPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    caseStudyHero,
    copyLockupList,
    featuredContents,
    pitch,
    sectionHeader,
    testimonials,
    videoPlayer
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <CaseStudyHeroModule className={casestudyHero} data={caseStudyHero} />
      <SectionHeaderModule className={`${sectionHeaderClass} ${sectionHeader1}`} data={sectionHeader[0]} isLeftAligned />
      <VideoPlayerModule className={videoPlayerClass} data={videoPlayer} />
      <SectionHeaderModule className={`${sectionHeaderClass} ${sectionHeader2}`} data={sectionHeader[1]} isLeftAligned />
      <TestimonialsModule className={testimonialsClass} data={testimonials} />
      <SectionHeaderModule className={`${sectionHeaderClass} ${sectionHeader3}`} data={sectionHeader[2]} isLeftAligned />
      <CopyLockupListModule childrenLayout="fullWidthCopy" className={copyLockupListClass} data={copyLockupList} />
      <PitchModule className={pitchClass} data={pitch} />
      <FeaturedContentsModule className={featuredContentsClass} data={featuredContents} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingCaseStudyIndividualPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
        ...MarketingPageData
    }
  }
`;

MarketingCaseStudyIndividualPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCaseStudyHero']),
          ...CaseStudyHeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedContents']),
          ...FeaturedContentsDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentSectionHeader']),
          ...SectionHeaderDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentTestimonials']),
          ...TestimonialsDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentVideoPlayer']),
          ...VideoPlayerDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
