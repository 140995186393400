import { Testimonials } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import TestimonialModule, { TestimonialDataProps } from '../TestimonialModule';
import { marginContainer, wrap } from './TestimonialsModule.module.css';

export default function TestimonialsModule({
  blobs,
  className,
  data
}) {
  const {
    hasMarginBottom,
    tagline,
    testimonials,
    title
  } = data;

  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <Testimonials
        className={className}
        subtitle={tagline}
        title={title}
      >
        {testimonials.map((item) => (
          <TestimonialModule key={item.quote} data={item} />
        ))}
      </Testimonials>
    </div>
  );
}

export const TestimonialsDataPropsObject = {
  hasMarginBottom : PropTypes.bool,
  tagline         : PropTypes.string,
  testimonials    : PropTypes.arrayOf(TestimonialDataProps),
  title           : PropTypes.string
};

TestimonialsModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(TestimonialsDataPropsObject).isRequired
};

TestimonialsModule.defaultProps = {
  blobs     : null,
  className : null
};

export const TestimonialsModuleData = graphql`
fragment TestimonialsModuleData on ContentfulComponentTestimonials {
    hasMarginBottom
    tagline
    title
    testimonials {
      ...TestimonialModuleData
    }
}
`;
