import { Testimonial } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export default function TestimonialModule({ data }) {
  const {
    align,
    image,
    imageAlt,
    quote,
    source,
    sourceNote
  } = data;
  return (
    <Testimonial
      align={align}
      imageAlt={imageAlt}
      imageSrcFluid={image && image.fluid}
      quote={quote}
      source={source}
      sourceNote={sourceNote}
    />
  );
}

export const TestimonialDataProps = PropTypes.shape({
  align : PropTypes.oneOf(['center', 'left']),
  image : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  imageAlt   : PropTypes.string,
  quote      : PropTypes.string.isRequired,
  source     : PropTypes.string.isRequired,
  sourceNote : PropTypes.string
});

TestimonialModule.propTypes = {
  data : TestimonialDataProps.isRequired
};

export const TestimonialModuleData = graphql`
  fragment TestimonialModuleData on ContentfulComponentTestimonial {
    image {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageAlt
    quote
    source
    sourceNote
  }
`;
